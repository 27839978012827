import React from 'react';

interface FooterProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {}

export default function Footer(props: FooterProps) {
  return (
    <footer className="py-6 bg-gray-900">
      <div className="container mx-auto px-4">
        <section className="flex items-center justify-around">
          <dl>
            <dt className="mb-2 text-xs text-white">联系我们</dt>
            <dd>
              <address className="text-xs text-white opacity-60 not-italic">
                地址：
                <br />
                杭州市余杭区文一西路1198号
                <br />
                万利大厦1101室
              </address>
              <address className="text-xs text-white opacity-60 not-italic mt-2">
                电话：
                <br />
                13750966123 (刘经理)
                <br />
                18668235489 (谢经理)
                <br />
                0571-86652669
              </address>
            </dd>
          </dl>
          <div className="text-center">
            <img src={require('../assets/9733af.png')} className="w-20 h-20" alt="公众号二维码" />
            <p className="mt-2 text-white text-xs opacity-60">微信公众号</p>
          </div>
        </section>
        <section className="mt-12 text-xs text-gray-400 text-center">
          <p>Copyright © {new Date().getFullYear()} 轻派科技. All Rights Reserved.</p>
          <p>
            <a className="hover:text-white" href="https://beian.miit.gov.cn" target="_blank">
              浙ICP备16040055号-1
            </a>
          </p>
          <p>
            <a
              className="inline-flex items-center hover:text-white"
              target="_blank"
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010602011102"
            >
              <img src={require('../assets/ba.png')} className="w-3 h-3" />
              <span className="ml-1">浙公网安备 33010602011102号</span>
            </a>
          </p>
        </section>
      </div>
    </footer>
  );
}
