import classnames from 'classnames';
import { Link } from 'gatsby';
import React, { useState } from 'react';

interface HeaderProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
  nav: string;
}

export default function Header(props: HeaderProps) {
  const [mobileNavVisible, setMobileNavVisible] = useState(false);
  const navList = [
    { key: 'home', name: '首页', href: '/' },
    { key: 'solutions', name: '解决方案',href:'/solutions/' },
    { key: 'news', name: '轻派头条', href:'/news/'},
    { key: 'about', name: '关于轻派',href:'/about/' },
  ];
  console.log('测试')
  return (
    <header className="bg-white">
      <div className="container mx-auto px-4 flex items-center justify-between">
        <a href="/" className="py-2">
          <img src={require('../assets/51f000.png')} className="w-28" alt="Logo" />
        </a>
        <nav className="hidden sm:flex">
          {navList.map((item) => (
            <Link
              partiallyActive={true}
              key={item.name}
              to={item.href || `/${item.key}`}
              className={classnames(
                'flex items-center justify-center px-6 h-16 border-t-8 border-transparent hover:text-primary',
                props.nav === item.key && 'border-primary text-primary',
              )}
            >
              {item.name}
            </Link>
          ))}
        </nav>
        <div className="sm:hidden">
          <button
            className="inline-flex items-center justify-center p-2 rounded hover:bg-gray-50 focus:outline-none"
            onClick={() => setMobileNavVisible(!mobileNavVisible)}
          >
            <svg
              className="block h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
            <svg
              className="hidden h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
      <nav className={classnames('absolute z-10 inset-x-0 bg-white', mobileNavVisible ? 'block' : 'hidden')}>
        {navList.map((item) => (
          <Link
            partiallyActive={true}
            key={item.name}
            to={item.href || `/${item.key}`}
            className={classnames('block px-4 py-2', props.nav === item.key && 'text-primary font-bold')}
          >
            {item.name}
          </Link>
        ))}
      </nav>
    </header>
  );
}
