import React from 'react';
import Footer from './Footer';
import Header from './Header';

interface LayoutProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  nav: string;
}

export default function Layout(props: LayoutProps) {
  console.log('laypout', props)
  return (
    <div>
      <Header nav={props.nav} />
      {props.children}
      <Footer />
    </div>
  );
}
